<template>
    <div class="search-box">
        <el-input ref="searchInput" v-model="search" @keyup.enter.native="goSearch" class="search-input" size="small"
            :placeholder="flag ? $t('搜索单词') : $t('请输入编号/标题/关键词')">

            <div slot="suffix" class="flex flex-v-center flex-h-center ">
                <i @click="search = ''" v-if="search == $route.query.search"
                    class="margin-sx pointer suffix el-icon-close"></i>
                <i @click="goSearch" v-else-if="search && search.length > 2"
                    class="margin-sx pointer suffix el-icon-search"></i>
            </div>
        </el-input>
    </div>
</template>

<script>
export default {
    name: "searchInput",
    data() {
        return {
            search: "",
            flag: 0,
            searched: false,
        };
    },
    watch: {
        $route() {
            this.setFlag();
        }
    },

    created() {
        this.setFlag();
        if (this.$store.state.search) {
            this.search = this.$store.state.search;
        }

        this.$bus.$on("search_back", () => {
            this.search = "";
        })
    },

    methods: {
        setFlag() {
            if (this.$route.path != "/main/search" && this.$route.path != "/main/wordbook" && this.$route.path != "/main/vocabulary") {
                this.search = "";
            }
            if (this.$route.path == '/main/wordbook' || this.$route.path == '/main/vocabulary') {
                this.flag = 1;
            } else {
                this.flag = 0;
            }
        },

        goSearch() {
            if (this.search.trim().length > 1) {
                if (this.flag) {
                    if (this.$route.path == "/main/vocabulary") {
                        if (this.$route.query.search) {
                            this.$router.replace("/main/vocabulary?search=" + this.search)
                        } else {
                            this.$router.push("/main/vocabulary?search=" + this.search)
                        }
                    } else {
                        this.$router.push("/main/vocabulary?search=" + this.search)
                    }
                } else {
                    if (this.$route.path == "/main/search") {
                        this.$bus.$emit("search", this.search);
                        this.$router.replace("/main/search?search=" + this.search);
                        this.$store.commit("setSearch", this.search);
                    } else {
                        this.$router.push("/main/search?search=" + this.search);
                        this.$store.commit("setSearch", this.search);
                    }
                }

            } else {
                this.$message({
                    message: this.$t('最少输入两个字母'),
                    type: 'info'
                });
            }
        },
    },
};
</script>
<style scoped>
.search-box>>>.el-input__inner {
    border-radius: 20px;
    background-color: #f0f0f0;
    border: none;
}

.suffix {
    color: #e6a13c;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
}

.suffix:hover {
    color: #303133;
}
</style>
